@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;600&display=swap');

.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: 'League Spartan', sans-serif;
  font-weight: 200;
  background-color: #f0f0f0;
}

body {
  padding: 0;
  margin: 0;
}

/* .katex { font-size: 0.9em; } */